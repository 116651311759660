import React from "react";
import { Link } from "react-router-dom";
import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { useAccount } from "wagmi";

export default function Navbar() {
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const firstAddress = address ? address.slice(0, 4) : "";
  const lastAddress = address ? address.slice(-4) : "";
  const shortAddress = firstAddress + "..." + lastAddress;
  return (
    <nav>
      <Link aria-current="page" class="brand h2 font-secondary text" to="/">
        <span class="sign first">ABCD Fusion</span>
      </Link>
      <div class="nav-menu">
        <Link to={"/roadmap"} class="text nav-menu-item">
          Roadmap
        </Link>
        <Link to={"/about"} class="text nav-menu-item">
          About
        </Link>
        <Link to={"/faq"} class="text nav-menu-item">
          FAQ
        </Link>

        {isConnected ? (
          <Link class="text nav-menu-item btn-wallet" onClick={() => open()}>
            <span className="icon"></span> {shortAddress}
          </Link>
        ) : (
          <Link class="text nav-menu-item btn-wallet" onClick={() => open()}>
            {" "}
            Connect Wallet
          </Link>
        )}

        {/* <Web3Button icon="hide" avatar="show" /> */}
      </div>
    </nav>
  );
}
