import React from "react";
import { Link } from "react-router-dom";

export default function Faq() {
    return (
        <main>
            <div class="how-mint">
                <div class="hero rplr3 mt9">
                    <div class="hero-item">
                        <div class="hero-item-content">
                            <h3 class="h5 font-primary brand-color mb5">Your Guide to ABCD Fusion Key</h3>
                            <h1 class="h1 text grid-title mb3">
                                Everything You Need to Know About ABCD Fusion Key NFTs
                            </h1>
                            <p class="text">
                                Welcome to the ABCD Fusion Key FAQ, your comprehensive guide to understanding the intricacies of our revolutionary NFT collection. Whether you're a potential investor, developer, or enthusiast, find answers to your questions below.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="how-mint about-section dark-bg rplr3 pt9 pb9">
                    <div class="about-content">
                        <div class="about-content-text-list-box">
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">What is ABCD Fusion Key?</h4>
                                <p class="text">
                                    ABCD Fusion Key is a collection of 100,000 digital collectibles (NFTs) residing on the Ethereum blockchain, each generated algorithmically with over 600 traits. These NFTs offer exclusive benefits and perks within the ABCD Fusion ecosystem.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    How can businesses benefit from ABCD Fusion Key?
                                </h4>
                                <p class="text">
                                    ABCD Fusion Key is tailored for businesses seeking innovative opportunities in the dynamic Web 3 ecosystem. Businesses can collaborate, contribute, and shape the future alongside a forward-thinking community.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What opportunities does ABCD Fusion Key offer to developers?
                                </h4>
                                <p class="text">
                                    For developers, ABCD Fusion Key is a gateway to the world of Web 3. It provides a vibrant community where developers can engage in decentralized projects, collaborate with like-minded visionaries, and contribute to cutting-edge advancements.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    How can designers contribute to ABCD Fusion's ecosystem?
                                </h4>
                                <p class="text">
                                    Creative minds can unlock ABCD Fusion's potential with their artistic flair. Designers are invited to join and contribute to crafting visually stunning experiences that captivate and inspire the community.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What role do lawyers play in the ABCD Fusion Key ecosystem?
                                </h4>
                                <p class="text">
                                    Legal minds are invaluable in the ABCD Fusion Key ecosystem. Lawyers can engage in an exciting space where their insights contribute to building a transparent and legally sound environment.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What unique investment opportunities does ABCD Fusion Key offer to investors?
                                </h4>
                                <p class="text">
                                    ABCD Fusion Key offers a unique investment opportunity within the Web 3 space. Investors can actively participate in the growth of the ecosystem and explore the potential presented by NFTs and altcoins.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    How can traders leverage ABCD Fusion Key as a gateway to dynamic markets?
                                </h4>
                                <p class="text">
                                    Traders can use ABCD Fusion Key as a gateway to dynamic markets within the ecosystem. They can engage, navigate the evolving landscape, and explore potential altcoin trading opportunities.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    Can I access ABCD Fusion Key if I am under 18 years old?
                                </h4>
                                <p class="text mb15">
                                    No, the site is not intended for children. Users must be at least 18 years old to access ABCD Fusion Key.
                                </p>
                                <Link to="/terms" class="h3 text brand-color btn-wallet btn-wallet-secondary">
                                    Visit terms
                                </Link>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What is the process for minting ABCD Fusion Key NFTs?
                                </h4>
                                <p class="text">
                                    To mint ABCD Fusion Key NFTs, connect your Ethereum wallet to the ABCD Fusion website using MetaMask, Coinbase Wallet, or Trust Wallet. Select the quantity you want to mint, confirm the transaction (including the gas fee), and your NFTs will appear in your wallet.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    Why did ABCD Fusion Key choose a massive 100,000 NFT supply?
                                </h4>
                                <p class="text">
                                    The decision for a 100,000 NFT supply at 0.0042 ETH aims for inclusivity and balance, providing a wide distribution and an opportunity for a diverse audience, including investors and developers.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What is the significance of the 25 ETH giveaway for early minters?
                                </h4>
                                <p class="text mb15">
                                    Early minters have a chance to win up to 25 ETH. The top 10 wallets with the highest number of minted NFTs will share a generous 25 ETH, with the first wallet receiving 4.5 ETH.
                                </p>
                                <Link to="/earn" class="h3 text brand-color btn-wallet btn-wallet-secondary">
                                    Learn more
                                </Link>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    How are the ETH rewards distributed for the top minters?
                                </h4>
                                <p class="text">
                                    The top minters, ranging from the first to the tenth position, receive ETH rewards as follows: 4.5 ETH, 3.5 ETH, 3.5 ETH, 2.5 ETH, 2.5 ETH, 2.5 ETH, 1.5 ETH, 1.5 ETH, 1.5 ETH, and 1.5 ETH respectively.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What does the roadmap of ABCD Fusion Key entail?
                                </h4>
                                <p class="text mb15">
                                    The roadmap consists of three phases: Launch, Release of Utility Features, and Collaboration. Each phase introduces new elements, features, and community engagement opportunities, ensuring a dynamic and evolving ecosystem.
                                </p>
                                <Link to="/roadmap" class="h3 text brand-color btn-wallet btn-wallet-secondary">
                                    View roadmap
                                </Link>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    What does the future hold for the ABCD Fusion ecosystem?
                                </h4>
                                <p class="text">
                                    ABCD Fusion Keys go global, powering virtual worlds and decentralized social platforms. The ecosystem expands, Layer 2 platforms development and exciting collaborations are planned, including the ambitious goal of sending the first Fusion Key to Mars.
                                </p>
                            </div>
                            <div class="about-content-text-box">
                                <h4 class="text h3 mb15">
                                    How can participants contribute to the ongoing development of ABCD Fusion
                                </h4>
                                <p class="text">
                                    ABCD Fusion encourages ongoing community engagement and contributions. As the ecosystem evolves, participants can contribute their diverse ideas, ensuring a flavorful and exciting future for ABCD Fusion.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
