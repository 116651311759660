import React, { useState } from "react";
import Logo from "./image/ABCD.jpeg";
import icBusiness from "./image/ic_business.svg";
import icDesign from "./image/ic_designer.svg";
import icDev from "./image/ic_dev.svg";
import icInvestors from "./image/ic_investors.svg";
import icLaw from "./image/ic_law.svg";
import icTrade from "./image/ic_trade.svg";
import icEarn from "./image/ic_earn.svg";
import icDate from "./image/ic_date.svg";
import icCount1 from "./image/ic_count-1.svg";
import icCount2 from "./image/ic_count-2.svg";
import icCount3 from "./image/ic_count-3.svg";
import icCount4 from "./image/ic_count-4.svg";
import icCount5 from "./image/ic_count-5.svg";
import icCycle from "./image/ic_cycle.svg";
import icTwoWheeler from "./image/ic_two-wheeler.svg";
import icCar from "./image/ic_car.svg";
import icShuttle from "./image/ic_shuttle.svg";
import confi from "./config/Config.json";
import Abi from "./config/Abi.json";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { Web3Button, useWeb3Modal } from "@web3modal/react";
import { ethers } from "ethers";
import { Link } from "react-router-dom";
export default function Home() {
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const contractAddress = confi.CONTRACT_ADDRESS;
  const [cost, setCost] = useState("");
  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    if (count < 10) {
      setCount(count + 1);
    }
  };

  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const { data: minted } = useContractRead({
    address: contractAddress,
    abi: Abi,
    functionName: "totalSupply",
    watch: true,
  });

  const { data: totalSupply } = useContractRead({
    address: contractAddress,
    abi: Abi,
    functionName: "maxSupply",
    watch: false,
  });
  const { data: mintcost } = useContractRead({
    address: contractAddress,
    abi: Abi,
    functionName: "cost",
    watch: true,
  });
  const mintprice = String(mintcost * count);

  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: Abi,
    functionName: "mint",
    args: [count],
    overrides: {
      from: address,
      value: mintprice,
    },
  });
  const { data, write } = useContractWrite(config);
  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });

  return (
    <main>
      <div class="hero rplr3">
        <div class="hero-item">
          <div class="hero-item-content">
            <h3 class="h5 font-primary brand-color mb5">Explore the World of</h3>
            <h2 class="h1 text grid-title mb3">
              ABCD Fusion
            </h2>
            <p class="text mb3">
              Welcome to ABCD Fusion Key, a unique collection of 100,000 digital collectibles residing on the Ethereum blockchain. Each ABCD is one-of-a-kind, generated algorithmically from over 600 traits. Beyond being visually appealing NFTs, ABCD Fusion Key NFTs unlock exclusive benefits and perks for their holders.
            </p>
            <p class="text">
              As a proud ABCD Fusion Key NFT holder, you're not just part of a community – you're at the forefront of discovering new features and developments within the ABCD Fusion ecosystem. Enjoy early access, exclusive discounts, and promotions from our partners.
            </p>
          </div>
          <div class="hero-item-eth">
            <div class="hero-item-eth-box">
              <img class="mint-img" src={icEarn} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h5 font-primary brand-color mb5">Don’t miss a chance to win big!</h3>
                <h1 class="h1 text mb3">
                  Earn up to <span class="brand-color">25 ETH</span>
                </h1>
                <Link to="/earn" class="h3 text brand-color btn-wallet btn-wallet-secondary">
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="hero-item mint-area">
          <div class="mint-avatar-box">
            <img class="mint-img" src={Logo} alt="" />
            {/* <p class="h6 f-bold"><strong>*</strong> Metadata will be revealed after minting phase!</p> */}
          </div>
          <div class="mint-info">
            <div class="mint-collection">
              <h3 class="h3">
                {`${minted}`}/{`${totalSupply}`}
              </h3>
              <p class="h6 f-bold">
                <strong>*</strong> No minting limits per wallet
              </p>
            </div>
            <div class="mint-price">
              <h3 class="h3"> {`${mintcost / 1e18}`} ETH</h3>
              <p class="h6 f-bold">+ Gas Fee</p>
            </div>
          </div>
          <div class="mint-input">
            {/* <input type="text" placeholder="Enter Quantity eg:100" />
            <button class="mint-btn h5">Mint ABCD NFTs</button> */}

            {isConnected ? (
              <div>
                <div id="counter">
                  {/* <button className="counter-btn" onClick={handleDecrement}>
                    -
                  </button>
                  <span className="counter-txt">{count}</span>
                  <button className="counter-btn" onClick={handleIncrement}>
                    +
                  </button> */}
                </div>
                {isLoading && <div> Key minting... please Check your Wallet</div>}
                <div>
                  <button className="mint-btn h5">Minting TBA</button>
                  {/* <button
                    className="mint-btn h5"
                    disabled={!write || isLoading}
                    onClick={() => write?.()}
                  >
                    {isLoading ? "Minting..." : "MINT ABCD Fusion Key ⟩⟩"}
                  </button> */}

                  {isSuccess && (
                    <div>
                      <h1 className="h1 text-xs">Successfully minted your Key</h1>
                      <div>
                        <a
                          href={`https://etherscan.io/tx/${data?.hash}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="p-1 bg-red-500"
                        >
                          view on Ether scan
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div class="mint-btn no-d h5 f-bold" onClick={() => open()}>
                Minting TBA
                {/* <Web3Button icon="hide" /> */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div class="how-mint about-section dark-bg rplr3 pt9 pb9">
        <div class="about-content">
          <h3 class="h5 font-primary brand-color mb5 grid-sub-title">Who Benefits from</h3>
          <h2 class="h1 grid-title mb9">
            ABCD Fusion Key?
          </h2>
          <div class="about-content-text-list-box">
            <div class="about-content-text-box">
              <img class="mb3" src={icBusiness} alt="" />
              <h4 class="text mb15 h2">Business</h4>
              <p class="text">
                Tailored for businesses seeking innovative opportunities within the dynamic Web 3 ecosystem. Collaborate, contribute, and shape the future alongside a forward-thinking community.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3" src={icDev} alt="" />
              <h4 class="text mb15 h2">Developers</h4>
              <p class="text">
                For developers seeking a vibrant community, ABCD Fusion Key is the doorway to the world of Web 3. Dive into decentralized projects, collaborate with like-minded visionaries, and contribute to the cutting-edge advancements shaping our digital future.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3" src={icDesign} alt="" />
              <h4 class="text mb15 h2">Designers</h4>
              <p class="text">
                Creative minds, unlock ABCD Fusion's potential with your artistic flair. Join us in crafting visually stunning experiences that captivate and inspire our community.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3" src={icLaw} alt="" />
              <h4 class="text mb15 h2">Lawyers</h4>
              <p class="text">
                Legal minds, your expertise is invaluable. Engage in an exciting space where your insights contribute to building a transparent and legally sound environment.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3" src={icInvestors} alt="" />
              <h4 class="text mb15 h2">Investors</h4>
              <p class="text">
                ABCD Fusion Key offers a unique investment opportunity within the Web 3 space. Actively participate in growth and explore the potential presented by NFTs and altcoins.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3" src={icTrade} alt="" />
              <h4 class="text mb15 h2">Traders</h4>
              <p class="text">
                Traders, ABCD Fusion Key is your gateway to dynamic markets. Engage with our ecosystem, navigate the evolving landscape, and explore potential altcoin trading opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="how-mint rplr3 pt9 pb9">
        <div class="how-mint-content">
          <h3 class="h5 font-primary mb5 grid-sub-title">How to Mint Your</h3>
          <h2 class="h1 text grid-title mb9">
            ABCD Fusion Key?
          </h2>
        </div>
        <div class="how-mint-info">
          <div class="box">
            <img class="mb3 ic-count" src={icCount1} alt="" />
            <h3 class="text h3 mb15">Connect Your Wallet</h3>
            <p class="text">
              Connect your Ethereum wallet to the ABCD Fusion website using MetaMask, Coinbase Wallet, or Trust Wallet.
            </p>
          </div>
          <div class="box">
            <img class="mb3 ic-count" src={icCount2} alt="" />
            <h3 class="text h3 mb15">Select Your Quantity</h3>
            <p class="text">
              Choose the quantity of ABCD Fusion Key NFTs you want to mint, with no limits per wallet.
            </p>
          </div>
          <div class="box">
            <img class="mb3 ic-count" src={icCount3} alt="" />
            <h3 class="text h3 mb15">Confirm the Transaction</h3>
            <p class="text">
              Confirm the transaction to mint your NFTs, including the gas fee for Ethereum network mining.
            </p>
          </div>
          <div class="box">
            <img class="mb3 ic-count" src={icCount4} alt="" />
            <h3 class="text h3 mb15">Receive Your NFTs</h3>
            <p class="text">
              Once confirmed, your ABCD Fusion Key NFTs will appear in your wallet and can be viewed on ABCD Fusion or NFT marketplaces like OpenSea.
            </p>
          </div>
          <div class="box">
            <img class="mb3 ic-count" src={icCount5} alt="" />
            <h3 class="text h3 mb15">Earn ETH</h3>
            <p class="text">
              <Link
                to="/earn"
                class="text brand-color"
                rel="noreferrer"
              >Learn more</Link>{" "}
              about earning ETH through minting.
            </p>
          </div>
        </div>
      </div>
      <div class="how-mint about-section dark-bg rplr3 pt9 pb9">
        <div class="about-content">
          <h3 class="h5 font-primary brand-color mb5 grid-sub-title">Why Choose a</h3>
          <h2 class="h1 grid-title mb9">
            Massive 100,000 NFT Supply?
          </h2>
          <div class="about-content-text-list-box">
            <div class="about-content-text-box">
              <img class="mb3 ic-count" src={icCycle} alt="" />
              <h4 class="text h2 mb15">March 2023</h4>
              <p class="text">
                Our NFT journey began with 72 NFTs in March 2023, priced at 0.5 ETH, but we sought sustainability.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3 ic-count" src={icTwoWheeler} alt="" />
              <h4 class="text h2 mb15">
                July 2023
              </h4>
              <p class="text">
                Shifting to 700 NFTs at 0.1 ETH, we continued refining our approach for long-term development.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3 ic-count" src={icCar} alt="" />
              <h4 class="text h2 mb15">
                October 2023
              </h4>
              <p class="text">
                Settling on 10,000 NFTs at 0.08 ETH, we introduced a 100 ETH giveaway. However, challenges persisted.
              </p>
            </div>
            <div class="about-content-text-box">
              <img class="mb3 ic-count" src={icShuttle} alt="" />
              <h4 class="text h2 mb15">
                December 2023
              </h4>
              <p class="text">
                The ideal solution emerged – a 100,000 NFT supply at 0.0042 ETH, inclusive and balanced, with a 25 ETH giveaway.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
