import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
const chains = [mainnet];
const projectId = "f4d2984143cf717cb0228a57db55a520";

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  themeMode: "dark" | "light",
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
    <Web3Modal
      themeVariables={{
        "--w3m-font-family": "Mono, sans-serif",
        "--w3m-accent-color": "#1c1cff",
        "--w3m-background-color": "#1c1cff",
        "--w3m-accent-fill-color": "#ffff",
        "--w3m-button-border-radius": "0px",
        "--w3m-background-border-radius": "0px",
      }}
      projectId={"f4d2984143cf717cb0228a57db55a520"}
      ethereumClient={ethereumClient}
    />
  </React.StrictMode>
);
reportWebVitals();
