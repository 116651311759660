import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./Home";
import Footer from "./components/Footer";
import Roadmap from "./Roadmap";
import About from "./About";
import Earn from "./Earn";
// import Launch from "./Launch";
import Terms from "./Terms";
import Faq from "./Faq";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/roadmap" Component={Roadmap} />
        <Route path="/about" Component={About} />
        <Route path="/earn" Component={Earn} />
        {/* <Route path="/launch" Component={Launch} /> */}
        <Route path="/faq" Component={Faq} />
        <Route path="/terms" Component={Terms} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
