import React from "react";
import icTour from "./image/ic_tour.svg";
import icStrategy from "./image/ic_strategy.svg";
import icMountain from "./image/ic_mountain.svg";
import icPhase1 from "./image/ic_phase-1.svg";
import icPhase2 from "./image/ic_phase-2.svg";
import { Link } from "react-router-dom";

export default function Roadmap() {
  return (
    <main>
      <div class="how-mint">
        <div class="hero rplr3 mt9">
          <div class="hero-item">
            <div class="hero-item-content">
              <h3 class="h5 font-primary brand-color mb5">Our Journey Unveiled</h3>
              <h1 class="h1 text grid-title mb3">
                We're committed to our goals, no matter how long it takes.
              </h1>
              <p class="text">
                Our commitment is unwavering, and time is but a companion on our transformative journey. Each step we take, every milestone we reach, is driven by our resolute dedication to turning our vision into reality. Join us as we navigate challenges and embrace milestones, knowing that our commitment ensures progress towards fulfilling our aspirations.
              </p>
            </div>
          </div>
        </div>
        <div class="how-mint-info roadmap-info dark-bg rplr3 pt9 pb9">
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icTour} alt="" />
              <div class="hero-item-eth-box-content">
                <h5 class="h5 font-primary brand-color mb5">Phase 1</h5>
                <h2 class="h2 text">
                  Launch
                </h2>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Mint Your Exclusive ABCD Fusion Key NFTs</h3>
                <p class="text roadmap-list">
                  Dive into the vibrant NFT community by minting your unique ABCD Fusion Key NFTs. Whether you seek wealth or an exhilarating journey, our launch brings forth these exclusive NFTs for your enjoyment.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Win 25 ETH!</h3>
                <p class="text roadmap-list">
                  Participate in our thrilling giveaway for a chance to win an exciting 25 ETH! Embrace the excitement, and the most dedicated minters stand to claim this rewarding prize.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Altcoin Development</h3>
                <p class="text roadmap-list">
                  Experience enchantment as we introduce two additional altcoins to our ecosystem, adding magic to the project. Stay tuned for the Altcoin extravaganza as we embark on the development of Layer 2.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">10% - Pajama Shopping Spree</h3>
                <p class="text roadmap-list">
                  Join us in supporting our cause as we allocate 10% of funds to fuel our pajama addiction. Our comfy PJs might just inspire the next groundbreaking feature for the ABCD Fusion ecosystem.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Showcase Your Talents</h3>
                <p class="text roadmap-list">
                  Unleash your hidden talents! As we expand our community outreach, we actively seek extraordinary skills from ABCD Fusion Key NFT holders. Shine bright as we hire members to further develop our platform. <Link
                    to="https://discord.gg/q5vkwDq3sS"
                    target="_blank"
                    class="text"
                    rel="noreferrer"
                  >Get ready to shine!</Link>
                </p>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icStrategy} alt="" />
              <div class="hero-item-eth-box-content">
                <h5 class="h5 font-primary brand-color mb5">Phase 2</h5>
                <h2 class="h2 text">
                  Release Utility Features
                </h2>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Unveiling Mind-Blowing Utility Features</h3>
                <p class="text roadmap-list">
                  Brace yourself for features that elevate your ABCD Fusion Key NFT experience. Genie summoning powers might be involved! We're set to develop a Layer 2 dApp for gaming and ecosystem, ensuring an immersive user experience.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Exclusive Airdrops</h3>
                <p class="text roadmap-list">
                  Anticipate surprise airdrops that enrich your ownership experience. While the contents remain a secret, the anticipation will be worth it. Expect exclusive items like NFTs and coins in these airdrops.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Members-Only Auctions</h3>
                <p class="text roadmap-list">
                  Unlock a world of exclusive items, rewards, and experiences through our members-only auctions. Your ABCD Fusion Key NFT opens doors to unique opportunities, allowing users to participate in exclusive auctions for NFTs.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Public Auctions Open to All</h3>
                <p class="text roadmap-list">
                  Public auctions are now accessible to everyone! Bid on exclusive items, rewards, and experiences, showcasing the power of ABCD Fusion Key NFTs to the world. Users can participate in public auctions for NFTs.
                </p>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icMountain} alt="" />
              <div class="hero-item-eth-box-content">
                <h5 class="h5 font-primary brand-color mb5">Phase 3</h5>
                <h2 class="h2 text">
                  Collaboration
                </h2>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Altcoin Distribution</h3>
                <p class="text roadmap-list">
                  In 2040, we diligently work on the fair distribution of altcoins. Patience is key, and every ABCD Fusion Key NFT holder will receive their deserved share.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">P2P, P2E, C2E</h3>
                <p class="text roadmap-list">
                  Surprises await as we explore new territories in Peer-to-Peer, Play-to-Earn, and Create-to-Earn experiences. Dive into the details in our <Link
                    to="https://drive.google.com/file/d/13ZE_2MO9JTVaMDhCo80IIOCvj-9rA58g/view"
                    target="_blank"
                    class="text"
                    rel="noreferrer"
                  >whitepaper</Link> as we launch a Layer 2 dApp for gaming and ecosystem.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Project Ecosystem Expansion</h3>
                <p class="text roadmap-list">
                  Fast forward to 2045: our coins are global, powering virtual worlds and decentralized social platforms. Join us on intergalactic adventures as we collaborate to send our first Fusion Key to Mars. Allow users to experience the Layer 2 dApp gaming and ecosystem.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Collaboration with Web2 Giants</h3>
                <p class="text roadmap-list">
                  Exciting partnerships with Web2 giants are on the horizon. While we keep the details under wraps for now, get ready for incredible experiences coming your way through collaborations with Web2 giants and our community.
                </p>
              </div>
            </div>
            <div class="hero-item-eth-box mb3 pl98">
              <img class="mint-img" src={icPhase2} alt="" />
              <div class="hero-item-eth-box-content">
                <h3 class="h3 mb5 text">Continued Development</h3>
                <p class="text roadmap-list">
                  In 2046, our coins might just satisfy cravings for Ramen! Ongoing enhancements, driven by the diverse ideas of our community, ensure a flavorful and exciting future for the ABCD Fusion ecosystem.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
