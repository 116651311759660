import React from "react";
import { Link } from "react-router-dom";

export default function About() {
  return (
    <main>
      <div class="how-mint">
        <div class="hero rplr3 mt9">
          <div class="hero-item">
            <div class="hero-item-content">
              <h3 class="h5 font-primary brand-color mb5">We are building the ecosystem for next-gen!</h3>
              <h1 class="h1 text grid-title mb3">
                Weaving Dreams into Blockchain Reality
              </h1>
              <p class="text">
                ABCD Fusion transcends the ordinary; it's more than a platform—it's a canvas where dreams materialize, merging the ethereal with the practical to redefine the boundaries of decentralized aspirations.
              </p>
            </div>
          </div>
        </div>
        <div class="how-mint about-section dark-bg rplr3 pt9 pb9">
          <div class="about-content">
            {/* <h3 class="h5 font-primary mb5 grid-sub-title">Why choose</h3>
            <h2 class="h1 grid-title mb9">
              Massive 100,000 NFT Supply?
            </h2> */}
            <div class="about-content-text-list-box">
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">Into the ABCD Fusion</h4>
                <p class="text mb15">
                  Step into ABCD Fusion, where dreams take flight on our shared home, Earth. Beyond coding wizardry or financial expertise, our distinction lies in unyielding belief. Decades in the Web 2 realm fueled prosperity, akin to mythical unicorns.
                </p>
                <p class="text">
                  Undeterred by elusive success, we embark on a mission to construct the next unicorn in Web 3. We seek kindred spirits unafraid to navigate risks—a trio of ordinary individuals commencing this journey post the challenges of the 2022 pandemic.
                </p>
              </div>
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">
                  The Intersection of Human Thought and Blockchain
                </h4>
                <p class="text mb15">
                  In a rapidly evolving landscape of Blockchain, Crypto, and AI, we showcase the future. Our research, not developer-grade, forms the foundation of a likeminded community, where we aim to work, play, and earn together daily.
                </p>
                <p class="text">
                  Acknowledging our centralized beginnings, we aspire to decentralize, inviting you to join us in shaping this revolutionary journey.
                </p>
              </div>
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">
                  The Birth of ABCD Fusion
                </h4>
                <p class="text">
                  Despite rejection from fear-stricken seniors, our determination birthed ABCD Fusion. A testament to unwavering belief, our NFT project is an experimental endeavor—a shared triumph within our community. Failure is a lesson we embrace, persisting until dreams materialize into reality.
                </p>
              </div>
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">
                  Community-Centric Approach
                </h4>
                <p class="text mb15">
                  Our mission transcends selling 0.0042 ETH NFTs; it's an invitation to join our roadmap and community. Reinvesting 25 ETH back into our community, we kickstart a chain reaction. Despite dissenting voices, we believe in giving back, capturing attention, and fostering collective engagement.
                </p>
                <p class="text">
                  Overwhelmingly positive feedback fuels our excitement. Enthusiasm for ETH compensation opportunities is evident, and as believers in our collective journey, we actively participate in acquiring ABCD Fusion Key NFTs, emphasizing our commitment to community growth.
                </p>
              </div>
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">
                  ABCD Fusion Roadmap
                </h4>
                <p class="text mb15">
                  Embark on the ABCD Fusion Key Adventure! Mint NFTs, join the community, and stand a chance to win 25 ETH. 10% funds a pajama spree, and brace for Altcoin Development, mind-blowing features, and potential Genie summoning. Phase 2 brings upgraded utility, exclusive airdrops, and members-only auctions.
                </p>
                <p class="text mb15">
                  The Collaboration phase ensures fair altcoin distribution and explores exciting experiences. By 2045, our Fusion Keys go global, reaching intergalactic realms. Join the adventure – where every step is as exciting as the destination!
                </p>
                <Link to="/roadmap" class="h3 text brand-color btn-wallet btn-wallet-secondary">
                  View Roadmap
                </Link>
              </div>
              <div class="about-content-text-box">
                <h4 class="text h2 mb15">
                  About ABCD Fusion Key NFTs
                </h4>
                <p class="text mb15">
                  ABCD Fusion Key NFTs go beyond traditional digital collectibles, serving as gateways to exclusive benefits within the ABCD Fusion ecosystem. As holders, you lead in steering ABCD Fusion's development, enjoying exclusive discounts, and delving into unique experiences.
                </p>
                <p class="text mb15">
                  ABCD Fusion isn't just a project; it's a testament to belief and community power. Join, learn, and succeed with us. With your support, dreams turn into blockchain reality.
                </p>
                <p class="text">
                  Engage in our roadmap, contribute to the community, and collectively shape the future in the evolving Web 3 world. Welcome to the transformative journey with ABCD Fusion. 🚀
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
