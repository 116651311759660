import React from "react";
import icEvents from "./image/ic_events.svg";

export default function Earn() {
  return (
    <main>
      <div class="how-mint earn-page">
        <div class="hero rplr3 mt9">
          <div class="hero-item">
            <div class="hero-item-content">
              <h3 class="h5 font-primary brand-color mb5">Unlock the ABCD Fusion Key NFT Rewards – Mint and Win!</h3>
              <h1 class="h1 text grid-title mb3">
                ABCD Fusion Key NFT 25 ETH Giveaway for Early Minters!
              </h1>
              <p class="text">
                Boost your ETH earnings by minting more NFTs! The first wallet to mint the highest number of NFTs will claim a staggering 4.5 ETH. The runner-up will secure 3.5 ETH, and so on. Top 10 wallets collectively share a generous 25 ETH!
              </p>
            </div>
          </div>
        </div>
        <div class="how-mint-info earn-eth-info dark-bg rplr3 pt9 pb9">
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Painite</h3>
            </div>
            <h2 class="text h2 mb15">4.5 ETH</h2>
            <p class="text">
              Mint the most ABCD Fusion Key NFTs first and bag an impressive 4.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Benitoite</h3>
            </div>
            <h2 class="text h2 mb15">3.5 ETH</h2>
            <p class="text">
              Secure the second position in minting and pocket a handsome 3.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Taaffeite</h3>
            </div>
            <h2 class="text h2 mb15">3.5 ETH</h2>
            <p class="text">
              Be the third to mint the most ABCD Fusion Key NFTs and earn 3.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Poudretteite</h3>
            </div>
            <h2 class="text h2 mb15">2.5 ETH</h2>
            <p class="text">
              Claim 2.5 ETH as the fourth top NFT minter!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Grandidierite</h3>
            </div>
            <h2 class="text h2 mb15">2.5 ETH</h2>
            <p class="text">
              The fifth top minter receives a rewarding 2.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Red beryl</h3>
            </div>
            <h2 class="text h2 mb15">2.5 ETH</h2>
            <p class="text">
              Mint the sixth most NFTs and enjoy a 2.5 ETH reward!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Musgravite</h3>
            </div>
            <h2 class="text h2 mb15">1.5 ETH</h2>
            <p class="text">
              Seventh in line? You still get a fantastic 1.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Jeremejevite</h3>
            </div>
            <h2 class="text h2 mb15">1.5 ETH</h2>
            <p class="text">
              The eighth top minter grabs 1.5 ETH!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Eudialyte</h3>
            </div>
            <h2 class="text h2 mb15">1.5 ETH</h2>
            <p class="text">
              Ninth position secures 1.5 ETH for the lucky minter!
            </p>
          </div>
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <img class="mint-img" src={icEvents} alt="" />
              <h3 class="h5 earn-eth-title font-primary brand-color mb5">Petalite</h3>
            </div>
            <h2 class="text h2 mb15">1.5 ETH</h2>
            <p class="text">
              Tenth in line? You're not left out – enjoy a 1.5 ETH reward!
            </p>
          </div>
        </div>
        <div class="how-mint-content earn-mint-content dark-bg rplr3 pt9 pb9">
          <h3 class="h5 font-primary brand-color mb5 grid-sub-title">Stay Tuned for Exciting Updates – Your Big Win Awaits!</h3>
          <h2 class="h1 grid-title mb9">
            Don't miss out on this amazing opportunity to win substantial prizes!
          </h2>
          <div class="how-mint-content-text earn-mint-content-text">
            <div class="about-content-text-box mb3">
              <h3 class="h5 font-primary mb3 brand-color grid-sub-title">Pro Tips</h3>
              <h4 class="text mb15 h2">Maximize ETH Earnings through Minting:</h4>
              <p class="text mb15">
                Jump in early during the minting phase for a higher chance at a top spot.
              </p>
              <p class="text">
                Mint a significant number of NFTs to boost your odds of maximizing ETH earnings.
              </p>
            </div>
            <div class="about-content-text-box">
              <h3 class="h5 font-primary mb3 brand-color grid-sub-title">Contest Terms</h3>
              <h4 class="text mb15 h2">Participation Guidelines</h4>
              <p class="text mb15">
                The giveaway welcomes participants worldwide, fostering an inclusive global opportunity.
              </p>
              <p class="text mb15">
                The winner is determined by the wallet address with the highest number of minted ABCD NFTs.
              </p>
              <p class="text mb15">
                Official winner announcements will be made on various social media platforms post-minting phase, adding to the excitement.
              </p>
              <p class="text">
                ETH rewards will be distributed to the winner within 2 to 3 weeks post-announcement, ensuring a transparent and timely reward process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
