import React from "react";

export default function Terms() {
  return (
    <main>
      <div class="how-mint">
        <div class="hero rplr3 mt9">
          <div class="hero-item">
            <div class="hero-item-content">
              <h3 class="h5 font-primary brand-color mb5">Applicability</h3>
              <h1 class="h1 text grid-title mb3">
                Terms & Conditions
              </h1>
              <p class="text mb15">
                ABCD Fusion Key is a collection of digital artworks (NFTs) running on the Ethereum network. This website serves as an interface allowing participants to exchange digital collectibles. Users bear full responsibility for the safety and management of their private Ethereum wallets, and they must validate all transactions and contracts generated by this website before approval. Furthermore, as the ABCD Fusion Key smart contract operates on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.
              </p>
              <p class="text">
                This website and its connected services are provided "as is" and "as available" without warranty of any kind. By using this website, you accept sole responsibility for any and all transactions involving ABCD Fusion Key digital collectibles.
              </p>
            </div>
          </div>
        </div>
        <div class="how-mint-info roadmap-info dark-bg rplr3 pt9 pb9">
          <div class="box">
            <div class="hero-item-eth-box mb3">
              <h2 class="h2 mb3 text">
                Ownership
              </h2>
              <h3 class="text mb15">
                NFT Ownership
              </h3>
              <p class="text mb3">
                Each ABCD Fusion Key is an NFT on the Ethereum blockchain. Upon purchasing an NFT, you own the underlying ABCD Fusion Key, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may ABCD Fusion has rights to seize, freeze, or otherwise modify the ownership of any ABCD Fusion Key.
              </p>
              <h3 class="text mb15">
                Personal Use
              </h3>
              <p class="text mb1">
                Subject to your continued compliance with these Terms, ABCD Fusion grants you a worldwide, royalty-free license to use, copy, and display the purchased Art for personal, non-commercial use, including the creation or use of derivative works.
              </p>
              <p class="text mb1">
                <strong>Marketplaces:</strong> Participation in marketplaces facilitating NFT purchase and sale is permitted, contingent upon the marketplace verifying your ownership.
              </p>
              <p class="text mb3">
                <strong>Third-party Websites/Applications:</strong> Usage in third-party websites or applications is allowed, provided there is verification of ownership and control over the display.
              </p>
              <h3 class="text mb15">
                Commercial Use
              </h3>
              <p class="text mb6">
                Subject to your continued compliance with these Terms, ABCD Fusion grants you an unlimited, worldwide license for Commercial Use, enabling the use, copying, and display of purchased Art for creating derivative works (e.g.T-Shirt, merchandise, etc).
              </p>
              <h2 class="h2 mb3 text">
                Disclaimers
              </h2>
              <p class="text mb1">
                <strong>A.</strong> YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE OF THE SITE IS AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SITE AND ANY PART OF IT (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: <strong><i>(I)</i></strong> YOUR ACCESS TO OR USE OF THE SITE WILL MEET YOUR REQUIREMENTS, <strong><i>(II)</i></strong> YOUR ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, <strong><i>(III)</i></strong> USAGE DATA PROVIDED THROUGH THE SITE WILL BE ACCURATE, <strong><i>(IV)</i></strong> THE SITE OR ANY CONTENT, SERVICES, OR FEATURES MADE AVAILABLE ON OR THROUGH THE SITE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR <strong><i>(V)</i></strong> THAT ANY DATA THAT YOU DISCLOSE WHEN YOU USE THE SITE WILL BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
              </p>
              <p class="text mb1">
                <strong>B.</strong> YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFULL MISCONDUCT.
              </p>
              <p class="text mb6">
                <strong>C.</strong> WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF THE ETHEREUM NETWORK NOR DO WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES REGARDING ANY SMART CONTRACTS.
              </p>
              <h2 class="h2 mb3 text">
                Limitation of Liability
              </h2>
              <p class="text mb1">
                <strong>A.</strong> YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              </p>
              {/* <p class="text mb1">
                  <strong>B.</strong> YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $10.
                </p> */}
              <p class="text mb6">
                <strong>B.</strong> YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
              </p>
              <h2 class="h2 mb3 text">
                Risk Assumption
              </h2>
              <h3 class="text mb15">
                You accept and acknowledge each of the following:
              </h3>
              <p class="text mb1">
                <strong>A.</strong> To the extent that you sell your ABCD Fusion Key NFT, please be aware that the prices of NFTs are extremely volatile and fluctuations in the prices of other NFTs can impact the price of your ABCD Fusion Key both positively and negatively. Given the volatility, NFTs such as ABCD Fusion Key should not be considered an investment. You assume all risks in that connection.
              </p>
              <p class="text mb1">
                <strong>B.</strong> Ownership of an ABCD Fusion Key confers ownership of digital artwork only. Accordingly, no information on this Site (or any other documents mentioned therein) is or may be considered to be advice or an invitation to enter into an agreement for any investment purpose. Further, nothing on this Site qualifies or is intended to be an offering of securities in any jurisdiction nor does it constitute an offer or an invitation to purchase shares, securities, or other financial products. Due to the artistic nature of the project, ABCD Fusion has not been registered with or approved by any regulator in any jurisdiction. It remains your sole responsibility to assure that the purchase of the ABCD Fusion Key and the associated art is in compliance with laws and regulations in your jurisdiction.
              </p>
              <p class="text mb1">
                <strong>C.</strong> You assume all risks associated with using an Internet-based currency, including, but not limited to, the risk of hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your wallet.
              </p>
              <p class="text mb1">
                <strong>D.</strong> NFTs, cryptocurrencies, and blockchain technology are relatively new and the regulatory landscape is unsettled. New regulations could negatively impact such technologies, impacting the value of your ABCD Fusion Key. You understand and accept all risk in that regard.
              </p>
              <p class="text mb6">
                <strong>E.</strong> You assume all responsibility for any adverse effects of disruptions or other issues impacting Ethereum or the Ethereum platform.
              </p>
              <h2 class="h2 mb3 text">
                Age
              </h2>
              <p class="text mb6">
                Our Site is not intended for children. You must be at least 18 years old to access this Site or purchase an ABCD Fusion Key. If you are under 18 years old, you are not permitted to use this Site for any reason. By accessing the Site, you represent and warrant that you are at least 18 years of age.
              </p>
              <h2 class="h2 mb3 text">
                Indemnification
              </h2>
              <p class="text mb6">
                You agree to hold harmless and indemnify ABCD Fusion Key and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers, or partners from and against any claim, liability, loss, damage (actual and consequential) of any kind or nature, suit, judgment, litigation cost, and reasonable attorneys' fees arising out of or in any way related to <strong><i>(i)</i></strong> your breach of these Terms, <strong><i>(ii)</i></strong> your misuse of the Site, or <strong><i>(iii)</i></strong> your violation of applicable laws, rules, or regulations in connection with your access to or use of the Site.
              </p>
              <h2 class="h2 mb3 text">
                Changes to the Terms and Conditions
              </h2>
              <p class="text mb6">
                We may make changes to the Terms at our discretion. Please check these Terms periodically for changes. Any changes to the Terms will apply on the date they are made, and your continued access to or use after the Terms have been updated will constitute your binding acceptance of the updates. If you do not agree to any revised Terms, you may not access or use the Site.
              </p>
              <h4 class="text mb1">
                Effective Date and Updates
              </h4>
              <p class="text">
                These Terms & Conditions are effective as of [18/01/2024].
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
