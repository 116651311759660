import React from "react";
import { Link } from "react-router-dom";
import icX from "../image/ic_x.svg";
import icDiscord from "../image/ic_discord.svg";
// import icYouTube from "../image/ic_youtube.svg";

export default function Footer() {
  return (
    <footer>
      <div class="footer-content">
        <div class="footer-content-box">
          <h5 class="footer-title h2 mb15">ABCD Fusion</h5>
          <p class="mb15">Join three visionaries in revolutionizing Web3. ABCD Fusion is more than a platform; it's a testament to collaborative opportunities and limitless possibilities within the Web3 ecosystem.</p>
          <p class="mb3">Our commitment to innovation drives us to create a better future in collaboration with Web2 businesses. ABCD Fusion is an invitation to be part of the future we're actively building.</p>
        </div>
        <div class="footer-content-box">
          <h5 class="footer-title h2 mb15">Risk Disclaimer</h5>
          <p class="mb9">Cryptocurrency investments involve inherent risks due to market volatility. Investors should be aware of potential fluctuations in asset values. Tax implications vary by jurisdiction, and profits may be subject to capital gains or other taxes. Regulations surrounding cryptocurrencies are diverse, so it is crucial to understand the rules applicable in your area. We strongly recommend conducting thorough research and investing only what you can afford to lose. Stay informed and make well-informed decisions to navigate the dynamic landscape of cryptocurrency investments.</p>
        </div>
        <div class="footer-content-box">
          <div class="about-content-text-list-box">
            <div class="about-content-text-box">
              <Link
                to="https://abcdfusion.gitbook.io/whitepaper/"
                target="_blank"
                class="h3 text brand-color btn-wallet btn-wallet-secondary"
                rel="noreferrer"
              >Whitepaper</Link>
            </div>
            <div class="about-content-text-box mb6">
              <Link
                to="https://etherscan.io/address/0xdaf1928f4c8e0d133c7325e3e6e2d29e82e2bff4"
                target="_blank"
                class="h3 text brand-color btn-wallet btn-wallet-secondary"
                rel="noreferrer"
              >0xdaf1928f4c8e0d133c7325e3e6e2d29e82e2bff4</Link>
            </div>
            <div class="about-content-text-box mb6">
              <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSdJZojnD6QvCctHI6GQiwbzGXTtHpUbF1jYWRh0ORqoBkJs1Q/viewform?usp=sf_link"
                target="_blank"
                class="h3 text brand-color btn-wallet btn-wallet-secondary"
                rel="noreferrer"
              >Community Feedback and Launch Preferences Survey</Link>
            </div>
          </div>
        </div>
        <div class="footer-content-box">
          <p class="zpr">
            {" "}
            <span class="copy">&copy;</span>{" "}
            <span id="copyright">2023 - {new Date().getFullYear()}</span> ABCD Fusion. All
            Rights Reserved. Made with ❤️ from 🇮🇳 🇸🇪 🇭🇷
          </p>
          <div class="zpr">
            <Link
              to="https://twitter.com/abcdfusion"
              target="_blank"
              class="pp-content h5"
              rel="noreferrer"
            >
              <img class="mint-img mt0" src={icX} alt="" />
            </Link>
            {/* <Link
              to="https://www.youtube.com/@ABCDFusion"
              target="_blank"
              class="pp-content h5"
              rel="noreferrer"
            >
              <img class="mint-img mt0" src={icYouTube} alt="" />
            </Link> */}
            <Link
              to="https://discord.gg/q5vkwDq3sS"
              target="_blank"
              class="pp-content h5"
              rel="noreferrer"
            >
              <img class="mint-img mt0" src={icDiscord} alt="" />
            </Link>
            <Link to="/terms" class="pp-content p">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
